import { Typography } from 'antd';
import Link from 'next/link';
import * as React from 'react';
import { AlertOutlined, MailOutlined } from '@ant-design/icons';

export default function Footer() {
  return (
    <div className='flex-row wrap items-center justify-center px-10 font-medium z-10'>
      <Typography.Text type='secondary' className='text-sm'>
        &copy; 2022 - {new Date().getFullYear()}
      </Typography.Text>
      <Link href='https://lateotu.com' className='text-sm font-bold px-2'>
        <Typography.Text type='secondary'>境际之光</Typography.Text>
      </Link>
      <Link
        href='https://beian.miit.gov.cn'
        target='_blank'
        className='font-medium text-sm px-2'
        rel='noreferrer'
      >
        <Typography.Text type='secondary'>粤ICP备2022079532号</Typography.Text>
      </Link>
      <Link
        href='https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011302003606'
        target='_blank'
        className='font-medium text-sm px-2'
        rel='noreferrer'
      >
        <Typography.Text type='secondary'>
          粤公网安备44011302003606号
        </Typography.Text>
      </Link>
      <Link href='mailto:bussiness@lateotu.com'>
        <Typography.Text type='secondary' strong className='text-sm px-2'>
          <AlertOutlined /> 不良信息举报
        </Typography.Text>
      </Link>
    </div>
  );
}
