import * as React from 'react';
import useTheme from "antd/es/config-provider/hooks/useTheme";
import {useAppSelector} from "@/hooks";
import {selectTheme} from "@/store/theme";

type LogoPropsType = {
    fill?: string;
    className?: string;
};
export default function Logo(props: LogoPropsType) {
    const mode = useAppSelector(selectTheme);
    if (!props.fill) {
        props.fill = '#fff';
    }
    return (
        <div className={props.className}>
            <svg width="30px" height="30px"
                 viewBox="0 0 350 350"
                 version="1.1"
                 xmlns="http://www.w3.org/2000/svg">
                <title>切片</title>
                <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M175,58 C239.065031,58 291,109.934969 291,174 C291,212.006098 272.722181,245.743183 244.477162,266.900636 C270.989209,245.930045 288,213.475817 288,177.048507 C288,113.811903 236.736604,62.548507 173.5,62.548507 C110.769155,62.548507 59.8207636,112.995185 59.0098173,175.533547 L59,174 C59,109.934969 110.934969,58 175,58 Z"
                        id="形状结合" fill={mode==='dark'?'#fff':"#000"}></path>
                </g>
            </svg>
        </div>
    );
}
