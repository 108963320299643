import { Layout, message} from 'antd';
import dayjs from 'dayjs';
import {motion, useScroll, useSpring} from 'framer-motion';
import React, {useEffect} from 'react';
import 'dayjs/locale/zh-cn';

import 'antd/dist/reset.css';

import {useAppDispatch, useAppSelector} from '@/hooks';

import ConfigLayout from "@/components/Layout/ConfigLayout";
import Footer from '@/components/Layout/Footer';
import Header from '@/components/Layout/Header';

import {selectLocale, setLocale} from "@/store/locale";
import {fetchPlatformInfo} from "@/store/platform";
import {selectTheme, setTheme} from '@/store/theme';
import {fetchUserInfo, selectToken} from "@/store/user";

import { darkTheme, lightTheme } from '@/config/theme';

const platformLoading = {
    value: false
}
type LayoutProps = {
    sticky?: boolean;
    children: React.ReactNode;
    onThemeChange?: (theme: 'dark' | 'light') => void;
    onLocaleChange?: (locale: 'en-us' | 'zh-cn') => void;
    scrollProgress?: boolean;
    hideHeader?: boolean;
    hideFooter?: boolean;
    onScroll?: (e: any) => void;
    headerOpacityOnScroll?: boolean;
    side?: React.ReactNode;
    background?: React.ReactNode;
    onLogin?: () => void;
    onLogout?: () => void;
};

export default function PageLayout(props: LayoutProps) {
    // console.log('Layout 渲染');
    const token = useAppSelector(selectToken);
    const dispatch = useAppDispatch();
    const handleScroll = (e: any) => {
        props.onScroll && props.onScroll(e);
    };
    useEffect(() => {
        if (platformLoading.value) {
            return
        }
        platformLoading.value = true
        new Promise(() => {
            dispatch(fetchPlatformInfo())
            if (token) {
                dispatch(fetchUserInfo())
            }
        }).finally(() => {
            platformLoading.value = false
        })
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });
    const handleLocaleChange = (lang: 'en-us' | 'zh-cn') => {
        dispatch(setLocale(lang))
        if (lang === 'zh-cn') {
            dayjs.locale('zh-cn');
        } else if (lang === 'en-us') {
            dayjs.locale('en');
        }
    };
    const {scrollYProgress} = useScroll();

    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 100,
        restDelta: 0.001,
    });
    // 通过修改算法可以快速生成风格迥异的主题，
    // 5.0 版本中默认提供三套预设算法，
    // 分别是默认算法 theme.defaultAlgorithm、暗色算法 theme.darkAlgorithm
    // 和紧凑算法 theme.compactAlgorithm。
    // 你可以通过修改 ConfigProvider 中 theme 属性的 algorithm 属性来切换算法。
    //  theme={{algorithm: theme.darkAlgorithm}}
    message.config({
        top: 100,
        duration: 2,
        maxCount: 3,
    });
    return (
        <>
            <ConfigLayout key='antd-config'>
                <Layout className='relative min-h-screen pa-0 ma-0' id='hou-layout'>
                    {props.scrollProgress && (
                        <motion.div className='progress-bar' style={{scaleX}}/>
                    )}
                    {!props.hideHeader && (
                        <Header
                            headerOpacityOnScroll={props.headerOpacityOnScroll}
                            onLocaleChange={handleLocaleChange}
                            onScroll={handleScroll}
                            sticky={props.sticky}
                            onLogin={props.onLogin}
                            onLogout={props.onLogout}
                        />
                    )}
                    <Layout.Content className='min-h-content-full'>
                        <motion.div initial={{opacity: 0}} animate={{opacity: 1}}>
                            {props.children}
                        </motion.div>
                    </Layout.Content>
                    {!props.hideFooter && (
                        <Layout.Footer className='text-sm w-full'>
                            <Footer/>
                        </Layout.Footer>
                    )}
                    {props.background}
                </Layout>
            </ConfigLayout>
        </>
    );
}
