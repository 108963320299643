import { EditOutlined, PoweroffOutlined, SmileOutlined, UserOutlined,} from '@ant-design/icons';
import {
    Avatar,
    Button,
    Dropdown,
    Layout,
    MenuProps,
    Modal,
    notification,
    Select,
    Space,
    Switch,
    Typography,
} from 'antd';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from '@/hooks';

import IconFont from '@/components/IconFont';
import LoginForm from '@/components/LoginForm';
import Logo from '@/components/Logo';

import {clearToken, clearUserInfo, selectShowLogin, selectToken, selectUser, setShowLogin,} from '@/store/user';

import {logoutApi} from '@/api/user';
import {primaryColor} from "@/config/theme";
import {selectTheme, setTheme} from "@/store/theme";
import {selectLocale} from "@/store/locale";

const navigation = [
    {title: '主页', href: '/'},
    {title: '产品', href: '/product'},
    {title: '新闻资讯', href: '/news'},
    // { title: '知识库', href: '/knowledge' },
    {title: '服务与支持', href: '/support'},
    {title: '关于', href: '/about'},
];
const navigationEn = [
    {title: 'Home', href: '/'},
    {title: 'Product', href: '/product'},
    {title: 'Blog', href: '/news'},
    // { title: 'Knowledge', href: '/knowledge' },
    {title: 'Support', href: '/support'},
    {title: 'About', href: '/about'},
];
type HeaderMenuType = {
    title: string;
    href: string;
};
type HeaderPropsType = {
    onScroll?: (e: any) => void;
    sticky?: boolean;
    headerOpacityOnScroll?: boolean;
    onLocaleChange?: (locale: 'en-us' | 'zh-cn') => void;
    onLogin?: () => void;
    onLogout?: () => void;
};
export default function Header(props: HeaderPropsType) {
    // console.log('Header渲染');
    const router = useRouter();
    const mode = useAppSelector(selectTheme);
    const locale = useAppSelector(selectLocale) || 'zh-cn';
    const [headerMenus, setHeaderMenus] = useState<HeaderMenuType[]>(
        locale === 'en-us' ? navigationEn : navigation
    );
    const openLoginModal = useAppSelector(selectShowLogin);
    const user = useAppSelector(selectUser);
    const token = useAppSelector(selectToken);
    const setOpenLoginModal = (show: boolean) => {
        dispatch(setShowLogin(show));
    };
    const dispatch = useAppDispatch();

    const toggleMode = (val: boolean) => {
        const theme = val ? 'light' : 'dark';
        dispatch(setTheme(theme));
    };
    const [headerBackgroundOpacity, setHeaderBackground] = useState(0);
    const handleScroll = (e: any) => {
        // setScrollTop(window.document.documentElement.scrollTop)
        const scrollY = window.document.documentElement.scrollTop;
        // window.document.documentElement.scrollHeight
        // window.document.documentElement.clientHeight);
        // 计算背景透明度
        if (!props.headerOpacityOnScroll) {
            setHeaderBackground(1);
        } else {
            if (scrollY < 100) {
                setHeaderBackground(scrollY / 100);
            } else {
                setHeaderBackground(0.95);
            }
        }
        props.onScroll && props.onScroll(e);
    };
    const [api, contextHolder] = notification.useNotification();

    const handleLoginSuccess = () => {
        setOpenLoginModal(false);
        api.open({
            style: {top: '78px'},
            message: '登录成功',
            placement: 'top',
            description: '幸运与你随行～',
            icon: <SmileOutlined style={{color: '#108ee9'}}/>,
        });
        props.onLogin && props.onLogin();
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });
    useEffect(() => {
        // console.log('useEffect')
        setHeaderMenus(locale === 'en-us' ? navigationEn : navigation);
    }, [locale]);
    const handleChangLocale = (val: 'zh-cn' | 'en-us') => {
        // console.log('handleChangLocale')
        props.onLocaleChange && props.onLocaleChange(val);
    };
    const handleLogout = () => {
        logoutApi().then();
        dispatch(clearToken());
        dispatch(clearUserInfo());
        props.onLogout && props.onLogout();
    };
    return (
        <>
            <Modal
                className='relative'
                width={425}
                open={openLoginModal}
                onCancel={() => setOpenLoginModal(false)}
                centered
                style={{}}
                footer={null}
            >
                {/* todo 背景*/}
                <div className='mt-20 px-10 z-10'>
                    <LoginForm onSuccess={handleLoginSuccess}/>
                </div>
            </Modal>
            {contextHolder}
            <Layout.Header
                style={{
                    padding: '0 20px',
                    background: headerBackgroundOpacity
                        ? `rgba(${
                            mode === 'dark' ? '0,0,0' : '255,255,255'
                        },${headerBackgroundOpacity})`
                        : 'transparent',
                    position: props.sticky ? 'sticky' : 'relative',
                    top: 0,
                    zIndex: 99,
                }}
            >
                <div className='flex-row justify-sb items-center'>
                    <div className="flex-row justify-start items-center">
                        <Link href='/'>
                            <Logo
                                fill={mode === 'dark' ? '#fff' : '#000'}
                                className='flex-row items-center'
                            />
                        </Link>
                        <div className="sm-hide ml-10">
                            {headerMenus.map((item, index) => (
                                <Link
                                    key={item.title}
                                    href={item.href}
                                >
                                    <Typography.Text
                                        style={{
                                            margin: "0 20px",
                                            border: 'none',
                                            boxShadow: 'none',
                                            color: router.pathname === item.href ?primaryColor : '',
                                            backgroundColor: 'transparent',
                                        }}
                                        strong={router.pathname === item.href}
                                        key={index}
                                    >
                                        {item.title}
                                    </Typography.Text>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <Space/>
                    <div className='flex-row justify-end items-center'>
                        <div className='pl-4 pr-10'>
                            <Select
                                value={locale}
                                style={{width: '100px'}}
                                bordered={false}
                                onChange={handleChangLocale}
                            >
                                <Select.Option value='zh-cn' key='zh-cn'>
                                    <Space>
                    <span role='img' aria-label='中文'>
                      🇨🇳
                    </span>
                                        中文
                                    </Space>
                                </Select.Option>
                                <Select.Option value='en-us' key='en-us'>
                                    <Space>
                    <span role='img' aria-label='English'>
                      🇺🇸
                    </span>
                                        EN
                                    </Space>
                                </Select.Option>
                            </Select>
                            <Switch
                                rootClassName='mx-2'
                                defaultChecked={mode === 'light'}
                                onChange={toggleMode}
                                checkedChildren={<IconFont name='icon-light-mode'/>}
                                unCheckedChildren={<IconFont name='icon-dark_mode'/>}
                            />
                        </div>
                        {router.pathname !== '/login' && (
                            <>
                                {token ? (
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    label: (
                                                        <Link href='/personal'>
                                                            <UserOutlined className='mr-3'/>
                                                            {locale === 'en-us'
                                                                ? 'My space'
                                                                : '个人中心'}
                                                        </Link>
                                                    ),
                                                    key: '1',
                                                },
                                                {
                                                    label: (
                                                        <Link href='/write'>
                                                            <EditOutlined className='mr-3'/>
                                                            {locale === 'en-us'
                                                                ? 'Start write'
                                                                : '开始创作'}
                                                        </Link>
                                                    ),
                                                    key: '2',
                                                },
                                                {
                                                    type: 'divider',
                                                },
                                                {
                                                    label: (
                                                        <div onClick={handleLogout}>
                                                            <PoweroffOutlined
                                                                style={{color: 'red'}}
                                                                className='mr-3'
                                                            />
                                                            {locale === 'en-us'
                                                                ? 'Safe logout'
                                                                : '安全退出'}
                                                        </div>
                                                    ),
                                                    key: '3',
                                                },
                                            ],
                                        }}
                                    >
                                        <div className='flex-row items-center cursor-default'>
                                            <Avatar size={28} src={user?.avatar}>
                                                U
                                            </Avatar>
                                            <Typography.Text className='pl-2' strong>
                                                {user?.nickname || '无名用户'}
                                            </Typography.Text>
                                        </div>
                                    </Dropdown>
                                ) : (
                                    <Button
                                        type='primary'
                                        shape='round'
                                        onClick={() => setOpenLoginModal(true)}
                                    >
                                        {locale === 'en-us' ? 'Sign in' : '登录'}
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Layout.Header>
        </>
    );
}
