import {theme} from "antd";

export const primaryColor = '#0fe3a3'

export const darkTheme = {
    token: {
        mode: 'dark',
        colorPrimary: primaryColor,
        colorSuccess: '#52C41A',
        colorError: '#F5222D',
        fontSize: 14,
        wireframe: false,
    },
    algorithm: theme.darkAlgorithm,
};
export const lightTheme = {
    token: {
        mode: 'light',
        colorPrimary: primaryColor,
        colorSuccess: '#52C41A',
        colorError: '#F5222D',
        fontSize: 14,
        wireframe: false,
    },
    algorithm: theme.defaultAlgorithm,
};