import {
    FrownOutlined,
    LockFilled,
    LockOutlined,
    MobileOutlined,
    NumberOutlined,
    UserOutlined,
    WechatFilled,
    WechatOutlined,
} from '@ant-design/icons';
import {Button, Checkbox, Form, Input, notification, QRCode, Typography} from 'antd';
import Link from 'next/link';
import * as React from 'react';
import {useEffect, useState} from 'react';

import {useAppDispatch, useAppSelector} from '@/hooks';

import {fetchUserInfo, selectShowLogin, setToken} from '@/store/user';

import {getWechatMpCodeApi, loginApi, wechatMpLoginApi} from '@/api/user';

type LoginFormPropsType = {
    onSuccess: () => void;
};
let expiredTimer: any = undefined;
let mpQrTimer: any = undefined;

export default function LoginForm(props: LoginFormPropsType) {
    const [loginLoading, setLoginLoading] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [qrcodeIsError, setQrcodeIsError] = useState(false);
    const [loginMode, setLoginMode] = useState<'wechat' | 'mobile' | 'password'>(
        'wechat'
    );
    const openLoginModal = useAppSelector(selectShowLogin);

    const [qrcodeStatus, setQrcodeStatus] = useState<
        'active' | 'expired' | 'loading'
    >('active');
    const [wechatMpQrcode, setWechatMpQrcode] = useState(
        {
            url: 'https://lateotu.com/login',
            ticket: '',
        }
    );
    const [accountVerify, setAccountVerify] = useState<
        'success' | 'warning' | 'error' | 'validating' | ''
    >('');
    const [accountHelpMsg, setAccountHelpMsg] = useState('');
    const [passwordVerify, setPasswordVerify] = useState<
        'success' | 'warning' | 'error' | 'validating' | ''
    >('');
    const [passwordHelpMsg, setPasswordHelpMsg] = useState('');
    const dispatch = useAppDispatch();
    let checkTimeLimit = 30;
    const [api, contextHolder] = notification.useNotification();

    const getWechatMpQrcode = () => {
        if (expiredTimer) {
            console.log("clear time out ", expiredTimer)
            clearTimeout(expiredTimer);
        }
        if (mpQrTimer) {
            clearInterval(mpQrTimer)
        }
        setQrcodeStatus('loading');
        getWechatMpCodeApi({platform: 'official'}).then((res) => {
            setWechatMpQrcode(
                res.result
            );
            setQrcodeStatus('active');
            expiredTimer = setTimeout(() => {
                setQrcodeStatus('expired');
                clearInterval(mpQrTimer)
            }, 1000 * 60);
            console.log("expiredTimer", expiredTimer)
            mpQrTimer = setInterval(() => {
                if (checkTimeLimit <= 0) {
                    console.log('CheckTimeLimit is 0 , clearInterval MpQrTimer');
                    clearInterval(mpQrTimer);
                }
                checkTimeLimit -= 1;
                wechatMpLoginApi({ticket: res.result.ticket}).then((loginRes) => {
                    if (!loginRes.success) {
                        api.open({
                            style: {top: '78px'},
                            message: '登录失败',
                            placement: 'top',
                            type: 'error',
                            description: loginRes.message,
                            icon: <FrownOutlined style={{color: 'orange'}}/>,
                        })
                        clearInterval(mpQrTimer);
                        return;
                    }
                    if (!loginRes.result) {
                        console.log(`Check login callback ${checkTimeLimit}...`);
                        return;
                    }
                    clearInterval(mpQrTimer);
                    dispatch(setToken(loginRes.result.access_token));
                    dispatch(fetchUserInfo());
                    props.onSuccess && props.onSuccess()
                })
                    .catch((err) => {
                        console.log(err);
                        clearInterval(mpQrTimer);
                    });
            }, 2000);
        }).catch(() => {
            setQrcodeIsError(true)
        })
    };
    useEffect(() => {
        console.log('Login form useEffect')
        if (!openLoginModal) {
            clearInterval(mpQrTimer)
            clearTimeout(expiredTimer)
        } else {
            if (!agreed) {
                return
            } else {
                getWechatMpQrcode();
            }
        }
    }, []);
    const handleRefreshQrcode = () => {
        getWechatMpQrcode();
    };
    const onFinish = (values: any) => {
        console.log('Received values of form: ', values);
        setPasswordVerify('validating');
        setPasswordHelpMsg('');
        setAccountVerify('validating');
        setAccountHelpMsg('');
        if (loginLoading) {
            return;
        }
        if (!values.password) {
            setPasswordVerify('error');
            setPasswordHelpMsg('请输入密码');
            return;
        }
        setLoginLoading(true);
        setTimeout(() => {
            loginApi(values)
                .then((res) => {
                    if (res.success) {
                        dispatch(setToken(res.result.access_token));
                        dispatch(fetchUserInfo());
                        props.onSuccess();
                    } else {
                        if (res.code === 4001) {
                            setPasswordVerify('error');
                            setPasswordHelpMsg('密码错误');
                        } else if (res.code === 4002) {
                            setAccountVerify('error');
                            setAccountHelpMsg('账号不存在');
                        }
                    }
                })
                .finally(() => {
                    setLoginLoading(false);
                });
        }, 400);
    };

    return (
        <>
            {loginMode === 'password' && (
                <div>
                    <div className='flex-row justify-center items-center mb-10'>
                        <LockFilled className='pr-4' style={{fontSize: '22px'}}/>
                        <span style={{fontSize: '20px'}}> 密码登录</span>
                    </div>
                    <Form
                        style={{height: '240px', padding: '40px 20px 0 20px'}}
                        name='login'
                        initialValues={{remember: true}}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name='account'
                            validateStatus={accountVerify}
                            help={accountHelpMsg}
                        >
                            <Input
                                size='large'
                                prefix={<UserOutlined className='site-form-item-icon'/>}
                                placeholder='账号/邮箱'
                            />
                        </Form.Item>
                        <Form.Item
                            name='password'
                            validateStatus={passwordVerify}
                            help={passwordHelpMsg}
                        >
                            <Input
                                size='large'
                                prefix={<LockOutlined/>}
                                type='password'
                                placeholder='密码'
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                disabled={!agreed}
                                loading={loginLoading}
                                size='large'
                                type='primary'
                                htmlType='submit'
                                className='w-full'
                            >
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            )}
            {loginMode === 'wechat' && (
                <div className='flex-col items-center justify-center pb-10'>
                    {contextHolder}
                    <div className='flex-row justify-center items-center mb-10'>
                        <WechatFilled className='pr-4' style={{fontSize: '28px'}}/>
                        <span style={{fontSize: '20px'}}> 微信登录</span>
                    </div>
                    {!agreed ? (
                        <div
                            style={{height: '240px'}}
                            className='flex-col justify-center items-center'
                        >
                            <Typography.Text type='secondary' style={{fontSize: '18px'}}>
                                {' '}
                                请先同意下方相关协议
                            </Typography.Text>
                        </div>
                    ) : (
                        qrcodeIsError ?
                            <Typography.Text type='secondary' style={{fontSize: '18px'}}>
                                {' '}
                                暂时无法登录
                            </Typography.Text>
                            :
                            <QRCode
                                status={qrcodeStatus}
                                size={240}
                                value={wechatMpQrcode.url || 'https://lateotu.com/login'}
                                onRefresh={handleRefreshQrcode}
                            />

                    )}
                </div>
            )
            }
            {
                loginMode === 'mobile' && (
                    <div
                        style={{height: '260px'}}
                        className='flex-col items-center justify-center pb-10'
                    >
                        <div>暂未开放</div>
                    </div>
                )
            }
            <div className='mt-4 flex-row justify-center'>
                <Checkbox
                    defaultChecked={false}
                    value={agreed}
                    onChange={() => {
                        if (!agreed) {
                            getWechatMpQrcode()
                        }
                        setAgreed(!agreed);
                    }}
                >
                    我已阅读并同意
                </Checkbox>
                <Link href='/agreement/doc?type=ua'>用户协议</Link>
                <span className='px-2'>和</span>
                <Link href='/agreement/doc?type=up'>隐私政策</Link>
            </div>
            <div className='flex-row justify-center mt-10'>
                {loginMode !== 'password' && (
                    <Button
                        icon={<NumberOutlined/>}
                        shape='round'
                        type='text'
                        onClick={() => setLoginMode('password')}
                    >
                        密码登录
                    </Button>
                )}
                {loginMode !== 'wechat' && (
                    <Button
                        icon={<WechatOutlined/>}
                        shape='round'
                        type='text'
                        onClick={() => setLoginMode('wechat')}
                    >
                        微信登录
                    </Button>
                )}
                {loginMode !== 'mobile' && (
                    <Button
                        icon={<MobileOutlined/>}
                        shape='round'
                        type='text'
                        onClick={() => setLoginMode('mobile')}
                    >
                        手机登录
                    </Button>
                )}
            </div>
        </>
    );
}
