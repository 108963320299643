import { createFromIconfontCN } from '@ant-design/icons';
import { CSSProperties } from 'react';

const IconfontCN = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3859227_rh1l296p9bd.js',
  // scriptUrl: ['//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js', '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js'],
});
type IconFontPropsType = {
  name: string;
  className?: string;
  height?: number | string | undefined;
  width?: number | string | undefined;
  style?: CSSProperties;
};
export default function IconFont(props: IconFontPropsType) {
  return (
    <IconfontCN
      height={props.height}
      width={props.width}
      className={props.className}
      style={props.style}
      type={props.name}
    />
  );
}
