import {ConfigProvider} from 'antd';
import type {Locale} from 'antd/es/locale';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import React, { useState} from 'react';
import 'dayjs/locale/zh-cn';

import 'antd/dist/reset.css';

import { useAppSelector} from '@/hooks';

import {selectLocale} from "@/store/locale";
import {selectTheme} from '@/store/theme';

import { darkTheme, lightTheme } from '@/config/theme';

type LayoutProps = {
    children: React.ReactNode;
};

export default function ConfigLayout(props: LayoutProps) {
    // console.log('Layout 渲染');
    const mode = useAppSelector(selectTheme);
    const lang = useAppSelector(selectLocale);
    return (
        <>
            <ConfigProvider theme={mode === 'dark' ? darkTheme : lightTheme} locale={lang === 'zh-cn' ? zhCN: enUS} key='antd-config'>
                {props.children}
            </ConfigProvider>
        </>
    );
}
